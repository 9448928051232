import React from 'react';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import styles from './Button.styles';

const CustomButton = (props) => {
    const classes = props.classes;
    return (
        <Button
            style={{ width: props.width, backgroundColor: props.BGcolor, color: props.color }}
            onClick={props.onClick}
            variant="contained"
            className={classes.root}
            type="submit"
        >
            {props.title}
        </Button>
    );
};

CustomButton.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.string,
    BGcolor: PropTypes.string,
};

CustomButton.defaultProps = {
    title: 'Нэмэх',
    color: 'white',
    BGcolor: '#185CCC',
    onClick: null,
};

export default withStyles(styles)(CustomButton);
