import React, { useState } from 'react';
// import { makeStyles } from '@mui/styles';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles()(() => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
        },
        title: {
            color: '#949494',
            marginBottom: 5,
            fontSize: 12,
            fontWeight: 600,
        },
        input: {
            flex: 1,
            height: 40,
            width: '100%',
            color: 'black',
            paddingLeft: 7,
            background: 'white',
            border: '2px solid #c8c8c8',
            boxSizing: 'border-box',
            borderRadius: 4,
            fontWeight: 600,
            '&:focus': {
                border: '2px solid red',
            },
        },
        disabled: {
            backgroundColor: '#f4f4f4',
        },
    };
});

export default function CustomInputBox(props) {
    const { classes } = useStyles();
    const [value, setValue] = useState(props.value);
    const [globalTimeout, setTimer] = useState(null);

    const handleChange = (e) => {
        props.onChange(e);
        setValue(e.target.value);
    };

    const handleKeyUp = (e) => {
        if (props.onKeyUp) {
            if (globalTimeout) {
                clearTimeout(globalTimeout);
                setTimer(null);
            }
            setTimer(
                setTimeout(() => {
                    props.onKeyUp(e);
                }, 2000)
            );
        }
    };

    return (
        <Box
            className={classes.root}
            style={{ width: props.width, flexDirection: props.row, alignItems: props.center }}
        >
            <Box>
                <Typography variant="h4" className={classes.title}>
                    {props.title}
                </Typography>
            </Box>
            <Box>
                <input
                    placeholder={props.placeholder}
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                    value={props.value}
                    type={props.type}
                    disabled={props.mode}
                    className={clsx({
                        [classes.input]: true,
                        [classes.disabled]: props.mode,
                    })}
                    readOnly={props.readOnly}
                    onWheel={(event) => event.currentTarget.blur()}
                />
            </Box>
        </Box>
    );
}

CustomInputBox.defaultProps = {
    width: 282,
    title: 'гарчиг',
    value: '',
    placeholder: '',
    onChange: null,
    onKeyUp: null,
    height: 127,
    type: null,
    mode: false,
    row: 'column',
    center: '',
    readOnly: false,
};
