import React from 'react';
import { makeStyles } from '@mui/styles';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles(() => ({
    alert: {
        width: 262,
        marginTop: 16,
        color: '#f84843',
        backgroundColor: '#ffe9e9',
        fontWeight: 600,
        border: 'none',
        alignItems: 'center',
    },
}));

export default function CustomAlert(props) {
    const classes = useStyles();

    return (
        <Alert severity={props.type} variant="outlined" className={classes.alert}>
            {props.message}
        </Alert>
    );
}

CustomAlert.defaultProps = {
    message: 'error handler',
    type: 'error',
};
