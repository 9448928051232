import { useState, useContext } from 'react';
import { useRouter } from 'next/router';
import { Box, Typography, Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FacebookLogin from 'react-facebook-login';

// components
import Alert from 'components/global/helpers/Alert';
import Button from 'components/global/form/Button';
import Input from 'components/global/form/Input';

// context
import { GlobalContext } from 'context';

// actions
import {
    login,
    facebookLogin,
    forgotEmail,
    confirmCodeCheck,
    setNewPassword,
} from 'context/actions/auth';

const useStyles = makeStyles(() => ({
    container: {
        width: 380,
        minHeight: 300,
        paddingTop: 30,
        paddingBottom: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    inputBox: {
        backgroundColor: '#FFF',
        padding: '30px 30px 45px 30px',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        marginBottom: 10,
    },
    forgotTitle: {
        fontSize: 12,
        cursor: 'pointer',
        marginTop: 7,
        fontWeight: 600,
        color: '#949494',
    },
    customTextField: {
        marginTop: 10,
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
        borderRadius: 4,
        // backgroundColor: 'red',
        width: '100%',
    },
    otherAuth: {
        marginTop: 30,
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    line: {
        height: 1,
        width: '42%',
        background: 'gray',
    },
    otherTitle: {
        fontSize: 12,
        cursor: 'pointer',
        padding: 7,
        // marginTop: 7,
        fontWeight: 600,
        color: '#949494',
    },
}));

const Login = ({ open, handleClose, handleRegister }) => {
    const classes = useStyles();
    const router = useRouter();
    const { dispatch } = useContext(GlobalContext);
    const [register, setRegister] = useState(false);

    // login
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // findme
    const [token, setToken] = useState(null);
    const [myEmail, setForgotEmail] = useState('');

    // set_password
    const [pass, setNewPass] = useState('');
    const [pass2, setNewPass2] = useState('');

    // confirm code
    const [confirmCode, setConfirmCode] = useState('');
    const [composeType, setComposeType] = useState('login');

    const [alert, setAlert] = useState({ show: false, message: '' });

    const responseFacebook = async (response) => {
        const datas = {
            name: response.name || null,
            facebookId: response.id,
            avatar: response.picture.data.url,
        };
        if (response.email) {
            Object.assign(datas, { email: response.email });
        }
        const result = await facebookLogin(dispatch, datas);

        if (result.errors && result.code === 404) {
            setAlert({
                show: true,
                message: 'Нэвтрэлт амжилтгүй боллоо!',
            });
        } else {
            clearAlert();
            if (result.data.info.type === 'STUDENT') router.push('/student/olympiads');
            else if (result.data.info.type === 'ADMIN') router.push('/admin/olympiads');
        }
    };

    const handleLogin = async () => {
        if (!email.length || !password.length) {
            setAlert({
                show: true,
                message: 'Нэвтрэх нэр болон нууц үгээ оруулна уу!',
            });
            return;
        }

        setAlert({ show: false, message: '' });

        const result = await login(dispatch, { email, password });
        if (result.errors && result.code === 404) {
            setAlert({
                show: true,
                message: 'Нэвтрэх нэр эсвэл нууц үг буруу байна!',
            });
        } else {
            clearAlert();
            if (result.data.info.type === 'STUDENT') router.push('/student/olympiads');
            else if (result.data.info.type === 'ADMIN') router.push('/admin/olympiads');
        }
    };

    const handleEmailCode = async () => {
        if (!myEmail.length) {
            setAlert({
                show: true,
                message: 'Утас эсвэл Имэйл хаягаа оруулна уу!',
            });
            return;
        }
        const res = await forgotEmail({ email: myEmail });
        if (res.code === 200) {
            setComposeType('confirm_code');
            setToken(res.data.resetToken);
            clearAlert();
        } else if (res.code === 404) {
            setAlert({
                show: true,
                message: 'Утас эсвэл Имэйл хаяг бүртгэгдээгүй байна',
            });
            return;
        }
    };

    const handleConfirmCode = async () => {
        if (!confirmCode.length) {
            setAlert({
                show: true,
                message: 'Баталгаажуулах кодоо оруулна уу!',
            });
            return;
        }
        const res = await confirmCodeCheck({ code: confirmCode, resetToken: token });
        if (res.code === 200) {
            clearAlert();
            setComposeType('set_password');
            setToken(res.data.resetToken);
            setAlert({ show: false });
        } else if (res.code === 404) {
            setAlert({
                show: true,
                message: 'Баталгаажуулах код буруу байна',
            });
            return;
        }
    };

    const handleSetNewPassWord = async () => {
        if (!pass2.length || !pass.length) {
            setAlert({
                show: true,
                message: 'Шинэ нууц үгээ оруулна уу!',
            });
            return;
        } else if (pass !== pass2) {
            setAlert({
                show: true,
                message: 'Нууц үгийн баталгаа буруу байна!',
            });
            return;
        }

        const res = await setNewPassword(dispatch, {
            password: pass,
            confirmPassword: pass2,
            resetToken: token,
        });

        if (res.code === 200) {
            clearAlert();
            if (res.data.info.type === 'STUDENT') router.push('/student/olympiads');
            else if (res.data.info.type === 'ADMIN') router.push('/admin/olympiads');
        } else if (res.code === 404) {
            setAlert({
                show: true,
                message: 'Баталгаажуулах код буруу байна',
            });
            return;
        }
    };

    const handleClickForgotPass = async () => {
        clearAlert();
        setComposeType('forgot');
    };

    const handleForgotEmailChange = (e) => setForgotEmail(e.target.value);
    const handleConfirmCodeChange = (e) => setConfirmCode(e.target.value);

    const handleUserNameChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);

    const handleNewPassChange = (e) => setNewPass(e.target.value);
    const handleNewPass2Change = (e) => setNewPass2(e.target.value);

    const clearAlert = () => {
        setAlert({
            show: false,
            message: null,
        });
    };

    if (composeType === 'login') {
        return (
            <Dialog
                classes={{ paper: classes.container }}
                maxWidth={'lg'}
                onClose={handleClose}
                open={open}
            >
                <form
                    id="my-form-id"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleLogin();
                    }}
                >
                    <Box className={classes.inputBox}>
                        <Typography variant={'h3'} className={classes.title}>
                            Нэвтрэх цонх
                        </Typography>
                        <Input
                            title="Утас эсвэл Имэйл хаяг"
                            onChange={handleUserNameChange}
                            value={email}
                        />
                        <Input
                            title="Нууц үг"
                            type="password"
                            onChange={handlePasswordChange}
                            value={password}
                        />
                        {alert.show && <Alert message={alert.message} />}
                        <Box>
                            <Typography
                                className={classes.forgotTitle}
                                onClick={handleClickForgotPass}
                            >
                                Нууц үгээ мартсан уу!
                            </Typography>
                        </Box>
                        <Box className={classes.buttons}>
                            <Button
                                type="submit"
                                title="Нэвтрэх"
                                width={'100%'}
                                // onClick={handleLogin}
                            />
                        </Box>
                        <Typography
                            className={classes.forgotTitle}
                            style={{ textAlign: 'center', marginTop: 25 }}
                            onClick={handleRegister}
                        >
                            Шинээр бүртгэл үүсгэх
                        </Typography>
                        <Box className={classes.otherAuth}>
                            <Box className={classes.line}></Box>
                            <Typography
                                className={classes.otherTitle}
                                style={{ textAlign: 'center' }}
                            >
                                Эсвэл
                            </Typography>
                            <Box className={classes.line}></Box>
                        </Box>
                        <FacebookLogin
                            size="small"
                            appId="302546468711290"
                            autoLoad={false}
                            fields="name,email,picture"
                            textButton="Фэйсбүүкээр нэвтрэх"
                            callback={responseFacebook}
                            cssClass="my-facebook-button-class"
                            redirectUri="https://olympiad.mn"
                            disableMobileRedirect={true}
                            isMobile={false}
                        />
                    </Box>
                </form>
            </Dialog>
        );
    } else if (composeType === 'forgot') {
        return (
            <Dialog
                classes={{ paper: classes.container }}
                maxWidth={'lg'}
                onClose={handleClose}
                open={open}
            >
                <Box className={classes.inputBox}>
                    <Typography variant={'h3'} className={classes.title}>
                        Нууц үг сэргээх
                    </Typography>
                    <Input
                        title="Утас эсвэл Имэйл хаяг"
                        type="email"
                        id={2}
                        onChange={handleForgotEmailChange}
                        value={myEmail}
                    />
                    {alert.show && <Alert message={alert.message} />}
                    <Box className={classes.buttons}>
                        <Button title="Илгээх" width={'100%'} onClick={handleEmailCode} />
                    </Box>
                </Box>
            </Dialog>
        );
    } else if (composeType === 'confirm_code') {
        return (
            <Dialog
                classes={{ paper: classes.container }}
                maxWidth={'lg'}
                onClose={handleClose}
                open={open}
            >
                <Box className={classes.inputBox}>
                    <Typography variant={'h3'} className={classes.title}>
                        Баталгаажуулах код
                    </Typography>
                    <Input
                        title="Баталгаажуулах код"
                        onChange={handleConfirmCodeChange}
                        value={confirmCode}
                        type="number"
                    />
                    {alert.show && <Alert message={alert.message} />}
                    <Box className={classes.buttons}>
                        <Button title="Илгээх" width={'100%'} onClick={handleConfirmCode} />
                    </Box>
                </Box>
            </Dialog>
        );
    } else if (composeType === 'set_password') {
        return (
            <Dialog
                classes={{ paper: classes.container }}
                maxWidth={'lg'}
                onClose={handleClose}
                open={open}
            >
                <Box className={classes.inputBox}>
                    <Typography variant={'h3'} className={classes.title}>
                        Нууц үг үүсгэх цонх
                    </Typography>
                    <Input
                        title="Шинэ нууц үг"
                        onChange={handleNewPassChange}
                        value={pass}
                        name="pass"
                        type="password"
                    />
                    <Input
                        title="Нууц үг давтах"
                        onChange={handleNewPass2Change}
                        value={pass2}
                        type="password"
                    />
                    {alert.show && <Alert message={alert.message} />}
                    <Box className={classes.buttons}>
                        <Button title="Нэвтрэх" width={'100%'} onClick={handleSetNewPassWord} />
                    </Box>
                </Box>
            </Dialog>
        );
    }
};

export default Login;
