const styles = () => ({
    root: {
        width: 'fit-content',
        backgroundColor: '#185CCC',
        padding: '10px 18px',
        borderRadius: 6,
        fontWeight: 600,
        color: 'white',
        // marginLeft: 5,
        // boxShadow: '0px 1px 6px #14193d6b',
        '&:hover': {
            backgroundColor: '#114499',
        },
    },
});

export default styles;
